var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('section',{staticClass:"gallery"},[_vm._m(0),_c('div',{staticClass:"frame"}),_c('div',{staticClass:"frame frame-bg"}),_c('div',{staticClass:"frame"}),_vm._m(1),_vm._m(2),_c('div',{staticClass:"frame"}),_vm._m(3),_c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-first-content"},[_c('video',{staticClass:"frame-media2 frame-media1-right",attrs:{"src":require("../assets/img/About/prog2.mp4"),"autoplay":"","loop":"","muted":""},domProps:{"muted":true}})])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32),_vm._m(33)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg"},[_c('div',{staticClass:"about-text",attrs:{"id":"element"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-first-content frame-media2 frame-media1-right"},[_c('span',{staticClass:"frame-text"},[_vm._v("IT is me :)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-first-content"},[_c('div',{staticClass:"frame-media1 frame-media1-left"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-first-content frame-media2 frame-media1-left"},[_c('span',{staticClass:"frame-text text-mob"},[_vm._v("IT is coding :)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content"},[_c('span',{staticClass:"frame-text-normal"},[_vm._v("I love that 💔")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" Some text about me 📜 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" I am engaged in layout "),_c('br'),_vm._v("and frontend development. "),_c('br'),_vm._v(" I have an infinitely "),_c('br'),_vm._v("strong desire to develop "),_c('br'),_vm._v("in this area. 💪 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content"},[_c('span',{staticClass:"frame-media2 frame-text-normal"},[_vm._v("And this is not the limit in my development ⏳")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" Professional Experience 👨🏻‍💻 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-content frame-text-normal"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Frontend Developer")]),_vm._v(" "),_c('br'),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("ESM Digital")]),_vm._v(" дек. 2023 - наст. вр."),_c('br'),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("In progress....")]),_vm._v(" "),_c('br')]),_c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-content frame-text-normal"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-content frame-text-normal"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Frontend Developer")]),_vm._v(" "),_c('br'),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Ligth Digital")]),_vm._v(" окт. 2023 - дек. 2023"),_c('br'),_vm._v(" • Разработал несколько компонентов высоконагруженного"),_c('br'),_vm._v(" интерфейса пользователя-продавца интернет-магазина"),_c('br'),_vm._v(" • Разработал двухстраничный сайт-презентацию мобильного приложения"),_c('br'),_vm._v(" • Разработал пользовательский интерфейс добавления формы мероприятия"),_c('br'),_vm._v(" • Доработал / дополнил UX|UI библиотеку ")]),_c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-content frame-text-normal"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-content frame-text-normal"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Frontend Developer")]),_vm._v(" "),_c('br'),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Hubnero")]),_vm._v(" (разработка ERP системы) | нояб. 2022 - окт. 2023"),_c('br'),_vm._v(" • Разработал UX/UI библиотеку функциональных компонентов, внедрил в проект"),_c('br'),_vm._v(" • Разработал 8 функциональных вкладок"),_c('br'),_vm._v(" • Разработал модульную библиотеку запросов со стора (ранее все запросы были прямо в компонентах)"),_c('br'),_vm._v(" • Менторинг и сопровождение двух новых разработчиков"),_c('br'),_vm._v(" • Изучил, подготовил документацию по переезду на Vue3"),_c('br')]),_c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-content frame-text-normal"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-content frame-text-normal"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Frontend Developer")]),_vm._v(" "),_c('br'),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("WebInc")]),_vm._v(" (веб-студия) | авг. 2022 - окт. 2022 "),_c('br'),_vm._v(" • Разработка лендидингов"),_c('br'),_vm._v(" • Поддержка и доработка уже готовых проектов"),_c('br'),_vm._v(" • Исправление багов"),_c('br'),_vm._v(" • Интенсивное обучение"),_c('br')]),_c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-content frame-text-normal"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" My Stack 📚 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame"},[_c('div',{staticClass:"frame-content frame-text-normal"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" • JS => ES6+ "),_c('br'),_vm._v(" • TS "),_c('br'),_vm._v(" • HTML5 "),_c('br'),_vm._v(" • CSS3 "),_c('br'),_vm._v(" • BEM "),_c('br'),_vm._v(" • Vue 2,3 JS "),_c('br'),_vm._v(" • Nuxt 2,3 "),_c('br'),_vm._v(" • Vue Router "),_c('br'),_vm._v(" • Vuex "),_c('br'),_vm._v(" • Pinia "),_c('br'),_vm._v(" • Vuetify "),_c('br'),_vm._v(" • Tailwind "),_c('br'),_vm._v(" • Axios "),_c('br'),_vm._v(" • Graph QL "),_c('br'),_vm._v(" • REST "),_c('br'),_vm._v(" • Git "),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" Are you ready? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" Scroll faster!!! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" G ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" G ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" G ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" G ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" G ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" G ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" O ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" O ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" O ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" O ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" O ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame frame-bg-opas"},[_c('div',{staticClass:"frame-content frame-text-normal"},[_vm._v(" O ")])])
}]

export { render, staticRenderFns }