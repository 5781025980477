<template>
  <div>
    <header>
      <div class="main-header">
        <div class="layers">
          <div class="layer-header">
            <div class=" layers-caption">Welcome to my Portfolio</div>
            <div class="layer-title">My Friend</div>
          </div>
          <div class="layer layers-base"></div>
          <div class="layer layers-middle"></div>
          <div class="layer layers-front"></div>
          <div class="enjoy">Turn on the audio, scroll down and enjoy ;)</div>
          <img
            class="arrow"
            src="../assets/img/arrow.svg"
            alt=""
          >
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
};
</script>

<style></style>