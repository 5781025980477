<template>
   <article class="main-article" id="main">
    <div class="article-content">
        <h3 class="article-header">
            See the magic 
        </h3>
    </div>
   </article>
</template>

<script>
export default {

}
</script>

<style>

</style>